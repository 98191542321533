const UUID_STORAGE_KEY = 'jbfo-queue-uuid'
const POSITION_STORAGE_KEY = 'jbfo-queue-position'

const createUUID = () => {
    let dt = new Date().getTime()
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (dt + Math.random() * 16) % 16 | 0
        dt = Math.floor(dt / 16)

        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
    })
}

export const setQueueId = () => {
    const uuid = createUUID()
    window.localStorage.setItem(UUID_STORAGE_KEY, uuid)
    resetInitialQueuePosition()
    return uuid
}

export const getQueueId = () => window.localStorage.getItem(UUID_STORAGE_KEY)

export const removeQueueId = () => {
    window.localStorage.removeItem(UUID_STORAGE_KEY)
    resetInitialQueuePosition()
}

export const setInitialQueuePosition = (position: number) =>
    window.localStorage.setItem(POSITION_STORAGE_KEY, position.toString())

export const resetInitialQueuePosition = () =>
    window.localStorage.removeItem(POSITION_STORAGE_KEY)

export const getInitialQueuePosition = () => {
    const position = window.localStorage.getItem(POSITION_STORAGE_KEY)

    return position ? parseInt(position, 10) : undefined
}
