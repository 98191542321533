import {
    Props,
    PriceBox as DefaultPriceBox,
} from 'components/src/presentation/PriceBox/PriceBox'

/* 
    Price legislations do not apply to Monstersale, 
    so use the default PriceBox without the lowest price & discount percentage.
*/
const PriceBox = (props: Props) => (
    <DefaultPriceBox
        {...props}
        showLowestPriceInfo={false}
        showPercentOff={false}
    />
)
export default PriceBox
