import { Picture } from '@emico-react/image'
import { MonstersaleLoginPage } from 'components/src/graphql/schema.generated'
import Container from 'components/src/layout/Container'
import Header from 'components/src/layout/Header'
import HeaderContainer from 'components/src/layout/Header/HeaderContainer'
import PageWrapper from 'components/src/layout/PageWrapper'
import ImageLazy from 'components/src/media/ImageLazy'
import breakpoints from 'components/src/theme/breakpoints'
import usePrismicSinglePageLogin from 'components/src/usePrismicSinglePageLogin'
import * as React from 'react'
import Helmet from 'react-helmet'

import { PrismicImageType } from '@emico/graphql-schema-types'
import { ImaginaryActions } from '@emico/ui'

import styles from './AccountLoginPageLayout.module.scss'
import { Faq } from './Faq'
import { LoginContent, LoginHeader } from './LoginFormContent'
import { Logos } from './Logos'

const AccountLoginPageLayout = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const { data: prismicPage } =
        usePrismicSinglePageLogin<MonstersaleLoginPage>('monstersale-login')

    if (!prismicPage) {
        return null
    }

    const { title } = prismicPage
    const titleFirstPart = title
        ?.split(' ')
        .slice(0, title.split(' ').length - 1)
        .join(' ')
    const titleLastPart = title?.split(' ').at(-1)

    const faqSubject = prismicPage.body?.find(
        (body) => body.__typename === 'MonstersaleLoginPageBodyFaqSubject',
    )

    const logoImages = prismicPage.logos?.map((logo) => logo.logoImage)

    return (
        <PageWrapper
            pageType="user"
            header={
                <HeaderContainer>
                    <Header showNavigation={false} />
                </HeaderContainer>
            }
            contentClassName={styles.pageWrapper}
        >
            <Helmet>
                {prismicPage?.seoTitle && (
                    <title>{prismicPage?.seoTitle}</title>
                )}
                {prismicPage?.seoDescription && (
                    <meta
                        name="description"
                        content={prismicPage?.seoDescription}
                    />
                )}
                {prismicPage?.seoKeywords && (
                    <meta name="keywords" content={prismicPage?.seoKeywords} />
                )}
            </Helmet>

            <div className={styles.desktopHeader}>
                {prismicPage?.backgroundImage?.url && (
                    <Picture
                        breakpoints={{
                            [breakpoints.xs]: {
                                url:
                                    prismicPage.mobileImage?.url ??
                                    prismicPage.backgroundImage.url,
                                width: 400,
                                height: undefined,
                            },
                            [breakpoints.md]: {
                                url: prismicPage.backgroundImage.url,
                                width: 1024,
                                height: undefined,
                            },
                            [breakpoints.lg]: {
                                url: prismicPage.backgroundImage.url,
                                width: 2000,
                                height: undefined,
                            },
                        }}
                        lazy
                        alt=""
                        className={styles.desktopImageWrapper}
                    />
                )}
                <div className={styles.desktopContentWrapper}>
                    <LoginContent
                        prismicPage={prismicPage}
                        titleFirstPart={titleFirstPart ?? ''}
                        titleLastPart={titleLastPart ?? ''}
                    />
                </div>
            </div>

            <div className={styles.mobileHeader}>
                <Container noPadding>
                    {prismicPage.backgroundImage?.url &&
                        prismicPage.mobileImage?.url && (
                            <>
                                {prismicPage.title && (
                                    <div className={styles.topHeadingMobile}>
                                        <Container>
                                            <LoginHeader
                                                color={
                                                    prismicPage.mobileImage.url
                                                        ? 'light'
                                                        : 'dark'
                                                }
                                                titleFirstPart={titleFirstPart}
                                                titleLastPart={titleLastPart}
                                            />
                                        </Container>

                                        {prismicPage.backgroundImage?.url && (
                                            <ImageLazy
                                                images={{
                                                    image: prismicPage
                                                        .backgroundImage.url,
                                                    image_mobile:
                                                        prismicPage.mobileImage
                                                            ?.url ??
                                                        prismicPage
                                                            .backgroundImage
                                                            .url,
                                                    regularWidth: 2000,
                                                    regularHeight: 300,
                                                    mediumWidth: 1024,
                                                    mediumHeight: 300,
                                                    smallWidth: 400,
                                                    smallHeight: 200,
                                                    action: ImaginaryActions.SMARTCROP,
                                                }}
                                                className={
                                                    styles.imageWrapperMobile
                                                }
                                                alt=""
                                            />
                                        )}
                                    </div>
                                )}
                                <LoginContent prismicPage={prismicPage} />
                            </>
                        )}
                </Container>
            </div>

            <Faq faqSubject={faqSubject} />
            <Logos images={logoImages as PrismicImageType[]} />
        </PageWrapper>
    )
}

export default AccountLoginPageLayout
