import FaqQuestions from 'components/src/FaqQuestions'
import { MonstersaleLoginPageBodyFaqSubject } from 'components/src/graphql/schema.generated'
import { getPrismicComponents } from 'components/src/htmlSerializer'
import TextColor from 'components/src/types/TextColor'

import { PrismicRichText, RichTextBlock } from '@emico/prismic'

import styles from './AccountLoginPageLayout.module.scss'

export const Faq = ({
    faqSubject,
}: {
    faqSubject?: MonstersaleLoginPageBodyFaqSubject
}) => {
    if (!faqSubject) {
        return null
    }
    const { primary, fields: faqItems } = faqSubject

    if (!faqItems) {
        return null
    }

    return (
        <div className={styles.faqContainer}>
            <FaqQuestions
                style="light"
                questions={faqItems?.map((faqItem) => ({
                    ...faqItem,
                    content: (
                        <PrismicRichText
                            render={faqItem.content as [RichTextBlock]}
                            components={getPrismicComponents({
                                style: TextColor.dark,
                            })}
                            analyticsContext="monstersale.faq.question.content"
                        />
                    ),
                }))}
                title={primary?.heading ?? ''}
            />
        </div>
    )
}
