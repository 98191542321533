import { gql, QueryHookOptions, useLazyQuery, useQuery } from '@apollo/client'
import { useAuthorizationContext } from '@emico-hooks/login-token'

export const query = gql`
    query GetQueuePosition($uid: ID!) {
        queuePosition(uid: $uid) {
            waitingAhead
            ready
        }
    }
`
export interface QueuePositionVariables {
    uid: string
}

export interface QueuePosition {
    waitingAhead: number
    ready: boolean
}

export interface GetQueuePositionData {
    queuePosition: QueuePosition
}

const useGetQueuePosition = (
    options?: QueryHookOptions<GetQueuePositionData, QueuePositionVariables>,
) => {
    const authorizationContext = useAuthorizationContext()
    const { data, error, loading } = useQuery<
        GetQueuePositionData,
        QueuePositionVariables
    >(query, {
        fetchPolicy: 'network-only',
        ...options,
        context: authorizationContext,
    })

    if (error) {
        throw error
    }

    return {
        data: data && data.queuePosition ? data.queuePosition : undefined,
        loading,
    }
}

export const useLazyGetQueuePosition = (
    options?: QueryHookOptions<GetQueuePositionData, QueuePositionVariables>,
) => {
    const authorizationContext = useAuthorizationContext()
    // eslint-disable-next-line no-restricted-syntax
    return useLazyQuery<GetQueuePositionData, QueuePositionVariables>(query, {
        fetchPolicy: 'network-only',
        ...options,
        context: authorizationContext,
    })
}

export default useGetQueuePosition
