import ImageLazy from 'components/src/media/ImageLazy'

import { PrismicImageType } from '@emico/prismic/src/responseTypes'

import styles from './AccountLoginPageLayout.module.scss'

export const Logos = ({ images }: { images: PrismicImageType[] }) =>
    images && images.length > 0 ? (
        <div className={styles.logoContainer}>
            {images.map(
                (image, index) =>
                    image?.url && (
                        <ImageLazy
                            key={index}
                            className={styles.logoImage}
                            images={{
                                image: image?.url,
                                image_mobile: image?.url,
                                regularWidth: 230,
                                mediumWidth: 230,
                                smallWidth: 150,
                            }}
                            alt=""
                        />
                    ),
            )}
        </div>
    ) : null
