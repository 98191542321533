import { defineMessage } from '@lingui/macro'
import makeUrl from 'components/src/makeUrl'

import { getActiveStoreView, setStoreViews, StoreView } from '@emico/storeviews'

const storeViews: Array<
    StoreView & {
        prismicLocale?: string
    }
> = [
    {
        code: 'monster_nl',
        language: 'nl',
        locale: 'nl-nl',
        name: defineMessage({
            id: 'core.storeConfig.monster_nl',
            message: `Netherlands`,
        }),
        location: undefined,
        makeUrl: makeUrl(),
        basename: '',
        default: true,
        storeId: 49,
        websiteId: 22,
        prismic: 'monstersale',
        prismicLocale: 'nl-nl',
    },
    {
        code: 'monster_be',
        language: 'nl',
        locale: 'nl-be',
        name: defineMessage({
            id: 'core.storeConfig.monster_be',
            message: `Belgium`,
        }),
        location: (location) =>
            location.pathname === '/be' || location.pathname.startsWith('/be/'),
        makeUrl: makeUrl('be'),
        basename: '/be',
        default: false,
        storeId: 50,
        websiteId: 23,
        prismic: 'monstersale',
        prismicLocale: 'nl-be',
    },
    {
        code: 'monster_de',
        language: 'de',
        locale: 'de-de',
        name: defineMessage({
            id: 'core.storeConfig.monster_de',
            message: `Germany`,
        }),
        location: (location) =>
            location.pathname === '/de' || location.pathname.startsWith('/de/'),
        makeUrl: makeUrl('de'),
        basename: '/de',
        default: false,
        storeId: 51,
        websiteId: 24,
        prismic: 'monstersale',
        prismicLocale: 'de-de',
    },
]

const activeStoreView = getActiveStoreView(storeViews, window.location)
setStoreViews(storeViews, activeStoreView)

export default storeViews
