import { MonstersaleLoginPage } from 'components/src/graphql/schema.generated'
import { getPrismicComponents } from 'components/src/htmlSerializer'
import Col from 'components/src/layout/Col'
import Container from 'components/src/layout/Container'
import Row from 'components/src/layout/Row'
import HeadingColor from 'components/src/types/HeadingColor'
import Heading from 'components/src/typography/Heading'
import Text from 'components/src/typography/Text'

import { PrismicRichText, RichTextBlock } from '@emico/prismic'
import { useBreakpoints } from '@emico/ui'

import Form from './AccountLoginPageForm'
import styles from './AccountLoginPageLayout.module.scss'

interface Props {
    prismicPage: MonstersaleLoginPage
    titleFirstPart?: string
    titleLastPart?: string
}

export const LoginHeader = ({
    titleFirstPart,
    titleLastPart,
    subTitle,
    color,
}: Omit<Props, 'prismicPage'> & {
    subTitle?: string
    color?: keyof typeof HeadingColor
}) => (
    <div className={styles.topHeading}>
        <div className={styles.headingMain}>
            <Heading variant="h2" element="h1" color={color ?? 'dark'}>
                {titleFirstPart}
            </Heading>
            <Heading variant="h2" element="h1" className={styles.highlight}>
                &nbsp;
                {titleLastPart}
            </Heading>
        </div>
        {subTitle && <Text className={styles.subHeading}>{subTitle}</Text>}
    </div>
)

export const LoginContent = ({
    prismicPage,
    titleFirstPart,
    titleLastPart,
}: Props) => {
    const { isMobile } = useBreakpoints()

    return (
        <>
            {!isMobile && prismicPage && prismicPage.title && (
                <Container>
                    <LoginHeader
                        color="dark"
                        titleFirstPart={titleFirstPart}
                        titleLastPart={titleLastPart}
                        subTitle={prismicPage.subTitle ?? ''}
                    />
                </Container>
            )}

            <Row noGutters>
                <Col md={6} className={styles.contentWrapper}>
                    <Container>
                        {prismicPage && prismicPage.subHeading && (
                            <Heading
                                variant="h3"
                                element="h2"
                                color="dark"
                                className={styles.formHeading}
                            >
                                {prismicPage.subHeading}
                            </Heading>
                        )}
                        {prismicPage && prismicPage.content && (
                            <PrismicRichText
                                render={prismicPage.content as [RichTextBlock]}
                                components={getPrismicComponents({
                                    contentClassName: styles.highlight,
                                })}
                                analyticsContext="accountloginpagelayout.loginpage.content"
                            />
                        )}
                    </Container>
                </Col>
                <Col className={styles.formContainer} md={6}>
                    <Form />
                </Col>
            </Row>
        </>
    )
}
