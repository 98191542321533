import { RouteType } from 'components/src/routes'
import retryingPromise from 'components/src/utils/retryingPromise'
import * as React from 'react'

import { QUEUE_ROUTES } from './constants'

// NOTE: When defining or renaming routes, please make sure the backend configuration (backend/app/etc/config.php) is
// also updated. Failure to do so will cause a 404 Status Code on your new / renamed route.
const routes: RouteType[] = [
    {
        path: QUEUE_ROUTES.entry,
        component: React.lazy(() =>
            retryingPromise(
                () =>
                    import(
                        /* webpackChunkName: "QueueEntryPage" */
                        './EntryPage/EntryPage'
                    ),
            ),
        ),
    },
    {
        path: QUEUE_ROUTES.catchAll,
        component: React.lazy(() =>
            retryingPromise(
                () =>
                    import(
                        /* webpackChunkName: "QueueCatchAllPage" */
                        './CatchAllPage/CatchAllPage'
                    ),
            ),
        ),
    },
]

export default routes
