import { css, Global } from '@emotion/react'
import { varKeys as colorVarKeys } from 'components/src/theme/colors'
import { varKeys as typographyVarKeys } from 'components/src/theme/typography'
import { varKeys as utilVarKeys } from 'components/src/theme/utils'

const ThemeOverrides = () => (
    <Global
        styles={css`
            :root {
                ${utilVarKeys.topbarMenuOffset}: 170px;

                ${colorVarKeys.grayDark}: #363636;
                ${colorVarKeys.primary}: #DA1F56;
                ${colorVarKeys.primaryExtra}: #6e7a7b;

                ${colorVarKeys.secondary}: #dc143c;
                ${colorVarKeys.secondaryExtra}: #ee3157;

                --color-highlight-cta: #da1f56;
                --color-highlight-cta-extra: #e64071;
                --color-highlight-cta-border-hover: transparent;

                ${colorVarKeys.badgeBg}: #dc143c;

                ${colorVarKeys.textWhite}: #fff;

                ${colorVarKeys.onBackground}: #363636;
                ${colorVarKeys.darkBackground}: #363636;

                ${typographyVarKeys.fonts.primary}: 'Roboto Slab', Helvetica,
                    Arial, sans-serif;
                ${typographyVarKeys.fonts.secondary}: 'Roboto Slab', Helvetica,
                    Arial, sans-serif;

                ${colorVarKeys.promoBannerBackground}: var(
                    ${colorVarKeys.sale}
                );

                ${colorVarKeys.footerTopBackground}: #363636;
                ${colorVarKeys.footerDivider}: #ffffff50;

                ${colorVarKeys.footerMainBackground}: var(
                    ${colorVarKeys.darkBackground}
                );
            }
        `}
    />
)

export default ThemeOverrides
