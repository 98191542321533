import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { QUEUE_ROUTES } from '../../queue/constants'
import { useLazyGetQueuePosition } from '../../queue/useGetQueuePosition'
import { getQueueId } from '../../queue/utils'

// Interval in seconds after which the queue status is checked
const INTERVAL = 60
let queueInitTime: number = new Date().getTime()

// When a customer enters the app, queue position is determined.
// When customer is allowed to shop, a "ready" flag is set to true within the queuePosition endpoint.
// This "ready" flag has a limited lifetime and is reset to false after a certain period of time (10 mins or 20 mins)
// unless the queuePosition is called within that period.
const useDetectQueueActivity = (isQueueEnabled: boolean = false): boolean => {
    const { pathname } = useLocation()
    const { push } = useHistory()
    const queueId = getQueueId()

    const [getQueuePosition, { data }] = useLazyGetQueuePosition({
        skip: !queueId || !isQueueEnabled,
        variables: {
            uid: queueId ?? '',
        },
    })

    // By watching the pathname variable,
    // the code within useEffect is executed on each route change.
    // This way we can detect if the user is active.
    //
    // The timedifference check is used to determine the number of seconds
    // between route changes. We wouldn't want the api call to be executed on each route
    // change as this is a waiste of data and affects server load.
    // Once every INTERVAL seconds is enough.
    useEffect(() => {
        if (!isQueueEnabled) {
            return
        }

        const currentTime = new Date().getTime()
        const timeDifference = Math.floor(currentTime - queueInitTime)

        if (timeDifference > INTERVAL * 1000) {
            queueInitTime = new Date().getTime()
            getQueuePosition()
        }
    }, [pathname, isQueueEnabled, getQueuePosition])

    // Do nothing if queue isn't enabled, or if user is on queue page already
    if (!isQueueEnabled || pathname === QUEUE_ROUTES.entry) {
        return false
    }

    // When queue id isn't set, user hasn't been in a queue
    // Redirect to queue page
    if (!queueId) {
        push(QUEUE_ROUTES.entry)
        return false
    }

    // When ready flag is false, user has been inactive for too long.
    // Redirect to the queue page so user enters the queue again.
    if (data && data.queuePosition && !data.queuePosition.ready) {
        push(QUEUE_ROUTES.entry)
        return false
    }

    return true
}

export default useDetectQueueActivity
