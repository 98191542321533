import sleep from './sleep'

export const DEFAULT_MAX_ATTEMPTS = 3
const ATTEMPT_DELAY = 500

export default function retryingPromise<T = unknown>(
    retryable: () => Promise<T>,
    // The max attempts before giving up. If set to 1 this will only try once.
    attempts: number = DEFAULT_MAX_ATTEMPTS,
    delay: number = ATTEMPT_DELAY,
): Promise<T> {
    attempts -= 1
    return retryable().catch((err: Error) => {
        if (attempts === 0) {
            return Promise.reject(err)
        }

        console.error(`An error occured, trying again in ${delay}ms`, err)

        return sleep(delay).then(() =>
            retryingPromise(retryable, attempts, delay * 2),
        )
    })
}
