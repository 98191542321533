import { useIsLoggedIn } from '@emico-hooks/login-token'
import { useGetCustomer } from 'components/src/customer/getCustomer.query'
import { useHistory, useLocation } from 'react-router-dom'

import { QUEUE_ROUTES } from '../../queue/constants'

const useCustomerCheck = (
    isQueueEnabled: boolean = false,
): string | undefined => {
    const { pathname } = useLocation()
    const isLoggedIn = useIsLoggedIn()

    // We require customer data to determine if some routes are accessible
    // We apply a cache first policy in order to prevent fetching data on each route request
    // However, when an order has been placed we need to get fresh results
    // The promo page also needs fresh results because userdata might already be present,
    const fetchPolicy =
        pathname.includes('/checkout-success') ||
        pathname.includes(QUEUE_ROUTES.entry)
            ? 'network-only'
            : 'cache-first'

    const { data: customer, loading } = useGetCustomer({
        skip: !isQueueEnabled || !isLoggedIn,
        fetchPolicy,
    })

    if (!isQueueEnabled) {
        return undefined
    }

    // Perform some customer related logic
    // These checks will be perfomed in the background so the user
    // doesn't notice this. If we were to wait for the customer data to
    // be available, (as we do most of the time), the user will get a blank page
    // Only users who are allowed to, may enter the site to shop
    if (!loading && customer && !customer.shoppingAllowed) {
        return QUEUE_ROUTES.catchAll
    }

    // Some users have a shopping limit.
    if (!loading && customer && customer.salesLimitsInfo?.status === 'LOCKED') {
        const url =
            customer.salesLimitsInfo?.redirectUrl || QUEUE_ROUTES.catchAll
        return url
    }
    return undefined
}

export default useCustomerCheck
