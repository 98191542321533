import { useIsLoggedIn } from '@emico-hooks/login-token'
import * as Sentry from '@sentry/react'
import { useCoreConfigValue } from 'components/src/coreConfig.query'
import { UrlRewriteEntityTypeEnum } from 'components/src/graphql/schema.generated'
import PageWrapper from 'components/src/layout/PageWrapper'
import NotFoundPage from 'components/src/NotFoundPage'
import paths from 'components/src/paths'
import PageLoader from 'components/src/presentation/PageLoader'
import { omitAuthRoutes } from 'components/src/utils/omitAuthRoutes'
import { useResolveUrl } from 'components/src/utils/resolveUrl'
import * as React from 'react'
import {
    Redirect,
    Route,
    Switch,
    useLocation,
    useHistory,
} from 'react-router-dom'

import useCustomerCheck from './useCustomerCheck'
import useDetectQueueActivity from './useDetectQueueActivity'
import { QUEUE_ROUTES } from '../../queue/constants'
import queueRoutes from '../../queue/Routes'
import useGetQueuePosition from '../../queue/useGetQueuePosition'
import { getQueueId, setQueueId } from '../../queue/utils'

// Create Custom Sentry Route component
const SentryRoute = Sentry.withSentryRouting(Route)

type PathedRoute = React.ComponentProps<typeof Route> & { path: string }
interface OwnProps {
    routes: PathedRoute[]
}

type Props = OwnProps

const ALLOWED_ROUTES = [
    paths.storeLocator,
    paths.logout,
    paths.login,
    paths.register,
    paths.registerAddress,
    paths.forgotPassword,
    paths.setPassword,
    paths.activateAccount,
]

const DefinedRoute = ({
    route,
    isQueueEnabled,
    isQueueReady,
}: {
    route: PathedRoute
    isQueueEnabled: boolean
    isQueueReady: boolean
}) => {
    const redirectUrl = useCustomerCheck(isQueueEnabled)
    const isLoggedIn = useIsLoggedIn() || omitAuthRoutes()
    const { push } = useHistory()

    React.useEffect(() => {
        if (redirectUrl) {
            push(redirectUrl)
        }
    }, [redirectUrl, push])

    if (!isQueueReady && !Object.values(QUEUE_ROUTES).includes(route.path)) {
        return <Redirect to={QUEUE_ROUTES.entry} />
    }

    // when you hit a disallowed path, kick to login
    if (!isLoggedIn && !ALLOWED_ROUTES.includes(route.path)) {
        return <Redirect to={paths.login} />
    }

    return <SentryRoute {...route} />
}

const DynamicRoute = ({
    route,
    isQueueEnabled,
    isQueueReady,
}: {
    route: PathedRoute
    isQueueEnabled: boolean
    isQueueReady: boolean
}) => {
    const redirectUrl = useCustomerCheck(isQueueEnabled)
    const { pathname } = useLocation()
    const { data: resolvedPage, loading, error } = useResolveUrl(pathname)
    const isLoggedIn = useIsLoggedIn() || omitAuthRoutes()

    const { push } = useHistory()

    React.useEffect(() => {
        if (redirectUrl) {
            push(redirectUrl)
        }
    }, [redirectUrl, push])

    if (error) {
        return <NotFoundPage />
    }

    if (!resolvedPage && loading) {
        return (
            <PageWrapper pageType="N/A">
                <PageLoader fullScreen reason="Resolving url..." />
            </PageWrapper>
        )
    }

    if (!resolvedPage) {
        return <NotFoundPage />
    }

    // Homepage is not allowed on monstersale when still in queue
    const isQueueNotReadyAndHomepage =
        !isQueueReady && resolvedPage.relativeUrl === paths.home

    if (isQueueNotReadyAndHomepage) {
        return <Redirect to={isQueueReady ? paths.login : QUEUE_ROUTES.entry} />
    }

    if (
        resolvedPage.type === UrlRewriteEntityTypeEnum.PRISMIC ||
        resolvedPage.type === UrlRewriteEntityTypeEnum.CMS_PAGE
    ) {
        // Pages defined in prismic can have an authRequired flag.
        // For those pages, a login is required.
        // Magento CMS pages are always accessible without login.
        return !isLoggedIn &&
            (resolvedPage.authRequired || pathname === paths.home) ? (
            <Redirect to={paths.login} />
        ) : (
            <SentryRoute {...route} />
        )
    } else {
        // For all other page types, like product pages, category pages, etc.
        // a login is required. Also, these pages require a valid address
        // for some unknown reason.
        return !isLoggedIn || !isQueueReady ? (
            <Redirect to={isQueueReady ? paths.login : QUEUE_ROUTES.entry} />
        ) : (
            <SentryRoute {...route} />
        )
    }
}

const RouteSwitch = ({
    isQueueEnabled,
    routes,
    isQueueReady,
}: {
    routes: Props['routes']
    isQueueEnabled: boolean
    isQueueReady: boolean
}) => {
    useDetectQueueActivity(isQueueEnabled)

    return (
        <Switch>
            {queueRoutes.map((route) => (
                <SentryRoute key={route.path} {...route} />
            ))}
            {routes.map((route) => (
                <SentryRoute
                    key={route.path}
                    path={route.path}
                    render={() =>
                        // The paths.home acts as a fallback where all routes that aren't matched resolve to.
                        route.path === paths.home ? (
                            <DynamicRoute
                                route={route}
                                isQueueEnabled={isQueueEnabled}
                                isQueueReady={isQueueReady}
                            />
                        ) : (
                            <DefinedRoute
                                route={route}
                                isQueueEnabled={isQueueEnabled}
                                isQueueReady={isQueueReady}
                            />
                        )
                    }
                />
            ))}
        </Switch>
    )
}

const AppRouter = ({ routes }: Props) => {
    const { value: queueEnabled, loading } = useCoreConfigValue(
        'jbfo_queue/queue/enabled',
        false,
        {
            fetchPolicy: 'network-only',
        },
    )

    const queueId = getQueueId() || setQueueId()
    const isQueueEnabled = queueEnabled === '1'

    const { data, loading: queueLoading } = useGetQueuePosition({
        variables: {
            uid: queueId,
        },
        skip: !queueEnabled,
    })

    if (loading || queueLoading) {
        return (
            <PageWrapper pageType="N/A">
                <PageLoader fullScreen reason="Loading queue status..." />
            </PageWrapper>
        )
    }

    return (
        <RouteSwitch
            isQueueEnabled={isQueueEnabled}
            isQueueReady={(data?.ready ?? false) || omitAuthRoutes()}
            routes={routes}
        />
    )
}

export default AppRouter
