import {
    Props,
    TileSlider as DefaultTileSlider,
} from 'components/src/TileSlider'
import HeadingColor from 'components/src/types/HeadingColor'
import TextColor from 'components/src/types/TextColor'

const TileSlider = (props: Props) => (
    <DefaultTileSlider
        {...props}
        headingColor={HeadingColor.dark}
        textColor={TextColor.dark}
    />
)

export default TileSlider
