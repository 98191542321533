import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import { Container } from 'components'
import { LoginError } from 'components/src/AccountAccessForm'
import { Email } from 'components/src/input/Email'
import FormGroup from 'components/src/input/FormGroup'
import { Label } from 'components/src/input/Label'
import { Password } from 'components/src/input/Password'
import Submit from 'components/src/input/Submit'
import SuccessMessage from 'components/src/input/SuccessMessage'
import Link from 'components/src/navigation/Link'
import paths from 'components/src/paths'
import { useSelector } from 'components/src/types'
import Heading from 'components/src/typography/Heading'
import { useLoginForm } from 'components/src/useLoginForm'
import validateRequired from 'components/src/utils/validateRequired'
import { Form } from 'informed'
import { useHistory } from 'react-router-dom'

import styles from './AccountLoginPageLayout.module.scss'

const Redirect = () => {
    const history = useHistory()
    const cartItemCount = useSelector(
        (state) => state.cart.details?.items_count,
    )

    let url =
        (process.env.REACT_APP_USER_LOGIN_REDIRECT_PATH as string) ||
        '/shop-all'

    if (cartItemCount) {
        url = '/cart'
    }

    history.push(url)

    return null
}

const AccountLoginPageForm = () => {
    const { handleSubmit, isSuccess, isLoading, error } = useLoginForm()

    if (isSuccess) {
        return <Redirect />
    }

    return (
        <Container>
            <Heading
                variant="h3"
                element="h3"
                color="dark"
                className={styles.heading}
            >
                <Trans id="account.login.heading.custom">
                    Login with an account
                </Trans>
            </Heading>
            <Form onSubmit={handleSubmit} noValidate data-cy="loginform">
                <LoginError error={error} />
                <SuccessMessage />

                <FormGroup>
                    <Label>
                        <Trans id="account.login.emailLabel">
                            Email address
                        </Trans>
                        <Email field="username" />
                    </Label>
                </FormGroup>

                <FormGroup>
                    <Label>
                        <Trans id="account.login.passwordLabel">Password</Trans>
                        <Password
                            field="password"
                            validate={validateRequired(
                                t({
                                    id: 'account.login.passwordRequiredMessage',
                                    message: `Please enter your password`,
                                }),
                            )}
                            autoComplete="current-password"
                            validateStrength={false}
                        />
                    </Label>
                </FormGroup>

                <Submit
                    variant="highlight"
                    wide
                    name="Log in"
                    category="user.loginForm.submit"
                    loading={isLoading}
                    disabled={isLoading}
                >
                    <Trans id="account.login.submit">Log in</Trans>
                </Submit>
            </Form>
            <Link
                name="Forgot passsord?"
                category="account.login"
                to={paths.forgotPassword}
                className={styles.forgotPasswordLink}
            >
                <Trans id="account.login.toForgotPassword">
                    Forgot your password?
                </Trans>
            </Link>
            <div className={styles.createUser}>
                <Trans id="account.login.toCreateUser">
                    New to PME Legend?{' '}
                    <Link
                        name="Create account"
                        category="account.login"
                        to={paths.register}
                        className={styles.createUserLink}
                    >
                        Create an account
                    </Link>
                </Trans>
            </div>
        </Container>
    )
}

export default AccountLoginPageForm
